import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  description: {
    id: 'feedback.noPrintersFound.description',
    defaultMessage:
      "We couldn't find any printers. Open our Spooler software on your desktop to set up printers. Or skip this step and set up your printers later.",
    description: 'Description of the no printers found feedback',
  },
  readDocumentationMessage: {
    id: 'feedback.noPrintersFound.readDocumentationMessage',
    defaultMessage:
      'Read our documentation for help with setting up your printers.',
    description: 'Read documentation message of the no printers found feedback',
  },
  searchPrinters: {
    id: 'common.searchPrinters',
    defaultMessage: 'Search printers',
    description: 'Common label for search printers',
  },
  skip: {
    id: 'actions.skip',
    defaultMessage: 'Skip',
    description: 'Common label for the skip action',
  },
  title: {
    id: 'feedback.noPrintersFound.title',
    defaultMessage: 'No printers found',
    description: 'Title of the no printers found feedback',
  },
});
