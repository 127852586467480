import { CardActionArea, styled } from '@afosto/components';

export const ActionArea = styled(CardActionArea)`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 112px;
  gap: ${(props) => props.theme.spacing(3)};
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(2.5)};
`;
