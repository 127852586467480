import { ShortcutCard } from '../ShortcutCard';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { Tag } from '../../icons/solid';
import type { ProductShortcutProps } from './types';

export const ProductShortcut = (props: ProductShortcutProps) => {
  const { sku, product, ...otherProps } = props;
  const [image] = product?.image || [];

  const { addItemsToOrder } = useOrder();

  const handleAddProductToOrder = async () => {
    try {
      await addItemsToOrder({
        items: [{ sku, quantity: 1 }],
      });
    } catch {
      //TODO: Do something with error.
    }
  };

  return (
    <ShortcutCard
      backgroundImage={image}
      Icon={!image ? Tag : undefined}
      label={product?.label || sku}
      onClick={handleAddProductToOrder}
      variant={image ? 'image' : 'filled'}
      {...otherProps}
    />
  );
};
