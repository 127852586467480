import {
  Container,
  Dialog,
  DialogHeader,
  Divider,
  Form,
} from '@afosto/components';
import { FormDialogTopBar } from '../FormDialogTopBar';
import type { FormDialogProps } from './types';

export const FormDialog = (props: FormDialogProps) => {
  const {
    children,
    description,
    dialogHeaderProps = {},
    formProps,
    hideDialogHeader = false,
    hideDialogHeaderDivider = false,
    onCancel,
    onSubmit,
    open,
    title,
    topBarProps = {},
    ...otherProps
  } = props;

  return (
    <Dialog {...otherProps} open={open} fullScreen>
      <Form onCancel={onCancel} onSubmit={onSubmit} {...formProps} hideActions>
        <FormDialogTopBar {...topBarProps} />
        <Container fixed maxWidth="md" sx={{ pb: 15 }}>
          {!hideDialogHeader && (
            <>
              <DialogHeader
                title={title}
                description={description}
                backButtonProps={{ size: 'medium' }}
                {...dialogHeaderProps}
                descriptionTypographyProps={{ variant: 'bodyLarge' }}
                titleTypographyProps={{ variant: 'h2' }}
                sx={{
                  px: 0,
                  pt: 4.5,
                  ...(!(dialogHeaderProps.description || description)
                    ? { borderBottom: '0!important', pb: '0px!important' }
                    : {}),
                  ...(hideDialogHeaderDivider ? { mb: 2.5 } : {}),
                }}
              />
              {!hideDialogHeaderDivider && <Divider sx={{ mb: 3, mt: 2.5 }} />}
            </>
          )}
          {children}
        </Container>
      </Form>
    </Dialog>
  );
};
