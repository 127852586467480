import { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import {
  createShortcut as createShortcutRequest,
  updateShortcuts as updateShortcutsRequest,
  type CreateShortcutInput,
  type UpdateShortcutsInput,
} from '../../../mutations';

export const useShortcuts = () => {
  const [showAddShortcutDialog, setShowAddShortcutDialog] = useState(false);
  const [showManageShortcutsDialog, setShowManageShortcutsDialog] =
    useState(false);

  const { mutateAsync: createShortcutMutation } = useMutation({
    mutationFn: createShortcutRequest,
  });

  const { mutateAsync: updateShortcutsMutation } = useMutation({
    mutationFn: updateShortcutsRequest,
  });

  const addShortcut = useCallback(
    async (input: CreateShortcutInput) => {
      return createShortcutMutation(input);
    },
    [createShortcutMutation]
  );

  const closeAddShortcutDialog = useCallback(() => {
    setShowAddShortcutDialog(false);
  }, []);

  const closeManageShortcutsDialog = useCallback(() => {
    setShowManageShortcutsDialog(false);
  }, []);

  const openAddShortcutDialog = useCallback(() => {
    setShowAddShortcutDialog(true);
  }, []);

  const openManageShortcutsDialog = useCallback(() => {
    setShowManageShortcutsDialog(true);
  }, []);

  const updateShortcuts = useCallback(
    (input: UpdateShortcutsInput) => {
      return updateShortcutsMutation(input);
    },
    [updateShortcutsMutation]
  );

  return {
    addShortcut,
    closeAddShortcutDialog,
    closeManageShortcutsDialog,
    openAddShortcutDialog,
    openManageShortcutsDialog,
    showAddShortcutDialog,
    showManageShortcutsDialog,
    updateShortcuts,
  };
};
