import { useIntl } from 'react-intl';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { ShortcutCard, type ShortcutCardProps } from '../ShortcutCard';
import { User } from '../../icons/solid';
import { translations } from './translations';

export const CustomerShortcut = (props: Partial<ShortcutCardProps>) => {
  const intl = useIntl();
  const {
    openAddCustomerDialog,
    openManageContactDialog,
    openManageOrganisationDialog,
    order,
  } = useOrder();
  const { contact, organisation } = order?.customer || {};
  const hasCustomer = !!contact?.id || !!organisation?.id;

  const handleClick = () => {
    if (hasCustomer) {
      if (contact) {
        openManageContactDialog(contact);
      } else if (organisation) {
        openManageOrganisationDialog(organisation);
      }
    } else {
      openAddCustomerDialog();
    }
  };

  return (
    <ShortcutCard
      color={hasCustomer ? 'success' : undefined}
      label={intl.formatMessage(
        translations[hasCustomer ? 'editCustomer' : 'addCustomer']
      )}
      Icon={User}
      onClick={handleClick}
      {...props}
    />
  );
};
