import { getProfile } from '@afosto/profile-service/api';
import { queryOptions } from '@tanstack/react-query';
import type { Category } from '../components/CategoryProvider';

export const LIST_CATEGORIES_QUERY_KEY = 'listCategories';

export interface ListCategoriesResponse {
  data: {
    pos: Category[];
  };
}

export const fetchCategories = async ({
  queryKey,
}: {
  queryKey: [string, object];
}): Promise<ListCategoriesResponse> => {
  const [, query = {}] = queryKey;

  const response = await getProfile({
    path: {
      path: 'consultancy.cmr.pos.tiles.v1',
    },
    query,
  });

  return response.data;
};

export const listCategories = (query = {}) => {
  return queryOptions({
    queryKey: [LIST_CATEGORIES_QUERY_KEY, query],
    queryFn: fetchCategories,
    select: (response) => response?.data?.pos || [],
  });
};
