import { useIntl } from 'react-intl';
import { ShortcutCard, type ShortcutCardProps } from '../ShortcutCard';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { Print } from '../../icons/solid';
import { translations } from './translations';

export const PrintLastReceiptShortcut = (props: Partial<ShortcutCardProps>) => {
  const intl = useIntl();
  const { getLastPrintedInvoice, printLastPrintedInvoice } = useOrder();
  const { printingEnabled } = usePrint();
  const lastPrintedInvoice = getLastPrintedInvoice();

  return (
    <ShortcutCard
      disabled={!printingEnabled || !lastPrintedInvoice}
      Icon={Print}
      label={intl.formatMessage(translations.printLastReceipt)}
      onClick={printLastPrintedInvoice}
      {...props}
    />
  );
};
