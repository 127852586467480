import { useIntl } from 'react-intl';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { translations } from '../../categoryTranslations';
import {
  SHORTCUT_CATEGORIES,
  SHORTCUT_CATEGORY_ICON_MAPPING,
} from '../../constants';
import type { ShortcutCategoriesFormSectionProps } from './types';

export const ShortcutCategoriesFormSection = (
  props: ShortcutCategoriesFormSectionProps
) => {
  const { onSelectCategory } = props;

  const intl = useIntl();

  return (
    <Fieldset>
      <ActionsList>
        {SHORTCUT_CATEGORIES.map((category) => (
          <CardSelector
            Icon={SHORTCUT_CATEGORY_ICON_MAPPING[category]}
            label={intl.formatMessage(
              translations[category as keyof typeof translations]
            )}
            onSelect={onSelectCategory(category)}
          />
        ))}
      </ActionsList>
    </Fieldset>
  );
};
