import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  cart: {
    id: 'common.cart',
    defaultMessage: 'Cart',
    description: 'Common label for cart',
  },
  pos: {
    id: 'common.pos',
    defaultMessage: 'Point of sale',
    description: 'Common label for pos',
  },
  product: {
    id: 'common.product',
    defaultMessage: 'Product',
    description: 'Common label for product',
  },
});
