import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link } from '@afosto/components';
import { sortArrayByProperty } from '@afosto/utils';
import { AddShortcutShortcut } from '../AddShortcutShortcut';
import { useApp } from '../AppProvider/hooks/useApp';
import { FullscreenDialog } from '../FullscreenDialog';
import { ShortcutsGrid } from '../ShortcutsGrid';
import {
  LIST_SHORTCUTS_QUERY_KEY,
  listShortcuts,
  type Shortcut,
} from '../../queries';
import { translations } from './translations';
import type { ManageShortcutsDialogProps } from './types';
import { DEFAULT_SHORTCUTS } from '../../constants/defaultShortcuts';

export const ManageShortcutsDialog = (props: ManageShortcutsDialogProps) => {
  const { onClose, open, topBarProps, TransitionProps, ...otherProps } = props;
  const { onExited } = TransitionProps || {};

  const { updateShortcuts } = useApp();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [shortcuts, setShortcuts] = useState<Shortcut[]>([]);

  const { data: shortcutsResponse, isFetching } = useQuery({
    ...listShortcuts(),
    enabled: open,
  });

  useEffect(() => {
    if (!isFetching) {
      const { items } = shortcutsResponse?.data || {};
      const shortcutItems = Object.values(items ?? {});

      if (shortcutItems.length > 0) {
        setShortcuts(
          sortArrayByProperty(shortcutItems, 'position') as Shortcut[]
        );
      } else {
        setShortcuts(DEFAULT_SHORTCUTS);
      }
    }
  }, [isFetching, shortcutsResponse]);

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleExited = async (node: HTMLElement) => {
    // TODO: optimize to only refetch when something changed.
    await queryClient.refetchQueries({
      queryKey: [LIST_SHORTCUTS_QUERY_KEY, {}],
      type: 'active',
    });

    if (onExited && typeof onExited === 'function') {
      onExited(node);
    }
  };

  const handleRemoveShortcut = async (id: string) => {
    const prevShortcuts = [...shortcuts];

    try {
      const updatedShortcuts = (shortcuts || [])
        .filter((shortcut) => shortcut.id !== id)
        .map((shortcut, idx) => ({
          ...shortcut,
          position: idx,
        }));

      setShortcuts(updatedShortcuts);

      await updateShortcuts({
        shortcuts: updatedShortcuts,
      });
    } catch {
      // TODO: DO something with error.
      setShortcuts(prevShortcuts);
    }
  };

  const handleShortcutSortingChanged = async (changedShortcuts: Shortcut[]) => {
    const prevShortcuts = [...shortcuts];

    try {
      const updatedShortcuts = changedShortcuts.map((shortcut, idx) => ({
        ...shortcut,
        position: idx,
      }));

      setShortcuts(updatedShortcuts);

      await updateShortcuts({
        shortcuts: updatedShortcuts,
      });
    } catch {
      // TODO: DO something with error.
      setShortcuts(prevShortcuts);
    }
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(translations.description)}
      onClose={handleClose}
      open={open}
      title={intl.formatMessage(translations.title)}
      topBarProps={{
        actions: (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
        ...(topBarProps || {}),
      }}
      TransitionProps={{
        ...(TransitionProps ?? {}),
        onExited: handleExited,
      }}
    >
      <ShortcutsGrid
        isLoading={isFetching}
        isSortable
        onRemove={handleRemoveShortcut}
        onSortingChanged={handleShortcutSortingChanged}
        shortcuts={shortcuts}
      >
        <AddShortcutShortcut />
      </ShortcutsGrid>
    </FullscreenDialog>
  );
};
