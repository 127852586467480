import { useIntl } from 'react-intl';
import { ShortcutCard, type ShortcutCardProps } from '../ShortcutCard';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { HourGlassEmpty } from '../../icons/solid';
import { translations } from './translations';

export const ParkReceiptShortcut = (props: Partial<ShortcutCardProps>) => {
  const intl = useIntl();
  const { order, parkOrder } = useOrder();

  return (
    <ShortcutCard
      disabled={!order?.id}
      Icon={HourGlassEmpty}
      label={intl.formatMessage(translations.parkReceipt)}
      onClick={parkOrder}
      {...props}
    />
  );
};
