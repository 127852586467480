import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  searchAnExistingProduct: {
    id: 'common.searchAnExistingProduct',
    defaultMessage: 'Search an existing product',
    description: 'Common label for search an existing product',
  },
  startASearch: {
    id: 'feedback.startASearch.message',
    defaultMessage: 'Start a search',
    description: 'Feedback message for start a search',
  },
});
