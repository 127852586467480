import { useIntl } from 'react-intl';
import { ShortcutCard, type ShortcutCardProps } from '../ShortcutCard';
import { useOrder } from '../OrderProvider/hooks/useOrder';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { CashRegister } from '../../icons/solid';
import { translations } from './translations';

export const OpenCashDrawerShortcut = (props: Partial<ShortcutCardProps>) => {
  const intl = useIntl();
  const { openCashDrawer } = useOrder();
  const { printingEnabled } = usePrint();

  return (
    <ShortcutCard
      disabled={!printingEnabled}
      Icon={CashRegister}
      label={intl.formatMessage(translations.openCashDrawer)}
      onClick={openCashDrawer}
      {...props}
    />
  );
};
