import { useQuery } from '@tanstack/react-query';
import { Box, ScrollContainer } from '@afosto/components';
import { sortArrayByProperty } from '@afosto/utils';
import { ManageShortcutsShortcut } from '../ManageShortcutsShortcut';
import { ShortcutsGrid } from '../ShortcutsGrid';
import { listShortcuts, type Shortcut } from '../../queries';
import { DEFAULT_SHORTCUTS } from '../../constants/defaultShortcuts';

export const Shortcuts = () => {
  const { data: shortcuts = [], isFetching } = useQuery({
    ...listShortcuts(),
    select: (response) => {
      const { items } = response?.data || {};
      const shortcutItems = Object.values(items ?? {});

      if (shortcutItems.length > 0) {
        return sortArrayByProperty(shortcutItems, 'position') as Shortcut[];
      }

      return DEFAULT_SHORTCUTS;
    },
  });

  return (
    <ScrollContainer>
      <Box sx={{ p: 2.5 }}>
        <ShortcutsGrid isLoading={isFetching} shortcuts={shortcuts}>
          <ManageShortcutsShortcut />
        </ShortcutsGrid>
      </Box>
    </ScrollContainer>
  );
};
