import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import { useApp } from '../AppProvider/hooks/useApp';
import { FormDialog } from '../FormDialog';
import { Loader } from '../Loader';
import { CartActionsFormSection } from './components/CartActionsFormSection';
import { PosActionsFormSection } from './components/PosActionsFormSection';
import { SearchProductFormSection } from './components/SearchProductFormSection';
import { ShortcutCategoriesFormSection } from './components/ShortcutCategoriesFormSection';
import { LIST_SHORTCUTS_QUERY_KEY } from '../../queries';
import { translations as categoryTranslations } from './categoryTranslations';
import { translations } from './translations';
import type {
  AddProductShortcutFormDialogData,
  AddShortcutFormDialogData,
  AddShortcutFormDialogProps,
} from './types';

export const AddShortcutFormDialog = (props: AddShortcutFormDialogProps) => {
  const {
    formProps,
    onCancel,
    open,
    topBarProps,
    TransitionProps,
    ...otherProps
  } = props;
  const { onExited } = TransitionProps ?? {};

  const { addShortcut, closeAddShortcutDialog } = useApp();
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const handleClearCategory = () => {
    setSelectedCategory(null);
  };

  const handleCancel = () => {
    if (onCancel && typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleExited = (node: HTMLElement) => {
    setIsSubmitting(false);
    setSelectedCategory(null);

    if (onExited && typeof onExited === 'function') {
      onExited(node);
    }
  };

  const handleSelectCategory = (category: string) => () => {
    setSelectedCategory(category);
  };

  const handleSubmit = async (data: AddShortcutFormDialogData) => {
    try {
      if (!selectedCategory) {
        return Promise.reject();
      }

      setIsSubmitting(true);

      if (selectedCategory === 'product') {
        const shortcutData = data as AddProductShortcutFormDialogData;

        await addShortcut({
          shortcut: {
            action: data.action,
            color: '#fff',
            settings: {
              sku: shortcutData.product?.sku,
            },
          },
        });
      } else {
        await addShortcut({
          shortcut: {
            action: data.action,
            color: '#fff',
            settings: {},
          },
        });
      }

      await queryClient.refetchQueries({
        queryKey: [LIST_SHORTCUTS_QUERY_KEY, {}],
        type: 'active',
      });

      setIsSubmitting(false);
      closeAddShortcutDialog();
    } catch {
      setIsSubmitting(false);
    }
  };

  return (
    <FormDialog
      {...otherProps}
      description={
        selectedCategory
          ? intl.formatMessage(
              categoryTranslations[
                selectedCategory as keyof typeof categoryTranslations
              ]
            )
          : undefined
      }
      dialogHeaderProps={{
        onBack: selectedCategory ? handleClearCategory : undefined,
      }}
      formProps={{
        hideSubmitButton: true,
        isSubmitting,
        submitLabel: intl.formatMessage(translations.add),
        ...(formProps ?? {}),
      }}
      hideDialogHeader={isSubmitting}
      onCancel={handleCancel}
      onSubmit={handleSubmit}
      open={open}
      title={intl.formatMessage(translations.title)}
      TransitionProps={{
        ...(TransitionProps ?? {}),
        onExited: handleExited,
      }}
    >
      {isSubmitting && (
        <Loader
          message={`${intl.formatMessage(translations.addingShortcut)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isSubmitting && !selectedCategory && (
        <ShortcutCategoriesFormSection
          onSelectCategory={handleSelectCategory}
        />
      )}
      {!isSubmitting && selectedCategory === 'product' && (
        <SearchProductFormSection />
      )}
      {!isSubmitting && selectedCategory === 'cart' && (
        <CartActionsFormSection />
      )}
      {!isSubmitting && selectedCategory === 'pos' && <PosActionsFormSection />}
    </FormDialog>
  );
};
