import { useIntl } from 'react-intl';
import { useApp } from '../AppProvider/hooks/useApp';
import { ShortcutCard } from '../ShortcutCard';
import { Cog } from '../../icons/solid';
import { translations } from './translations';

export const ManageShortcutsShortcut = () => {
  const intl = useIntl();
  const { openManageShortcutsDialog } = useApp();

  return (
    <ShortcutCard
      Icon={Cog}
      label={intl.formatMessage(translations.manageShortcuts)}
      onClick={openManageShortcutsDialog}
      variant="outlined"
    />
  );
};
