import { getProfile, updateProfile } from '@afosto/profile-service/api';
import { uuid } from '@afosto/utils';

export interface CreateShortcutInput {
  requestOptions?: object;
  shortcut: {
    id?: string;
    action: string;
    color?: string;
    position?: number;
    settings?: Record<string, unknown>;
  };
}

export const createShortcut = async ({ shortcut }: CreateShortcutInput) => {
  const generatedId = uuid();
  const newShortcut = {
    id: generatedId,
    ...shortcut,
  };

  const response = await getProfile({
    path: {
      path: `afosto.tenant.odr.pos.v1.shortcuts`,
    },
  });
  const currentShortcuts = response.data?.data?.items || [];

  newShortcut.position =
    currentShortcuts.length > 0 ? currentShortcuts.length : 0;

  await updateProfile({
    path: {
      path: `afosto.tenant.odr.pos.v1.shortcuts`,
    },
    body: {
      data: {
        items: [...currentShortcuts, newShortcut],
      },
    },
  });

  return newShortcut;
};
