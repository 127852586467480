import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Common label for close',
  },
  description: {
    id: 'feedback.dragShortcuts.message',
    defaultMessage: 'Drag shortcuts to rearrange their order',
    description:
      'Feedback message to indicate you can drag shortcuts to rearrange their order.',
  },
  title: {
    id: 'actions.manageShortcuts',
    defaultMessage: 'Manage shortcuts',
    description: 'Common label for manage shortcuts action',
  },
});
