import { useChannel } from '../../ChannelProvider/hooks/useChannel';
import { useAppActions } from './useAppActions';
import { useSearch } from './useSearch';
import { useShortcuts } from './useShortcuts';

export const useAppProvider = () => {
  const { channel } = useChannel();

  const appActions = useAppActions({ channel });
  const search = useSearch({ channel });
  const shortcuts = useShortcuts();

  return {
    ...appActions,
    ...search,
    ...shortcuts,
  };
};
