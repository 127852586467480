import { queryOptions } from '@tanstack/react-query';
import { getProfile } from '@afosto/profile-service/api';
import type { CamelCase } from '../types';

export const LIST_SHORTCUTS_QUERY_KEY = 'listShortcuts';

export type ShortcutAction =
  | 'ADD_CUSTOMER'
  | 'ADD_NOTE'
  | 'CANCEL_PRODUCTS'
  | 'CATEGORY'
  | 'MANUALLY_ADD_PRODUCT'
  | 'OPEN_CASH_DRAWER'
  | 'ORDER_DISCOUNT'
  | 'PARK_RECEIPT'
  | 'PRINT_LAST_RECEIPT'
  | 'PRODUCT'
  | 'SELECT_CATEGORY';

export type ShortcutActionKey = CamelCase<ShortcutAction>;

export interface Shortcut {
  id: string;
  action: ShortcutAction;
  color?: string;
  position: number;
  settings?: {
    sku?: string;
    [key: string]: unknown;
  };
}

export interface ListShortcutsResponse {
  data: {
    items: {
      [key: string]: Shortcut;
    };
    sorting: string[];
  };
}

export const fetchShortcuts = async ({
  queryKey,
}: {
  queryKey: [string, object];
}): Promise<ListShortcutsResponse> => {
  const [, query = {}] = queryKey;

  const response = await getProfile({
    path: {
      path: 'afosto.tenant.odr.pos.v1.shortcuts',
    },
    query,
  });

  return response.data;
};

export const listShortcuts = (query = {}) => {
  return queryOptions({
    queryKey: [LIST_SHORTCUTS_QUERY_KEY, query],
    queryFn: fetchShortcuts,
  });
};
