import { useIntl } from 'react-intl';
import { useApp } from '../AppProvider/hooks/useApp';
import { ShortcutCard } from '../ShortcutCard';
import { Plus } from '../../icons/solid';
import { translations } from './translations';

export const AddShortcutShortcut = () => {
  const { openAddShortcutDialog } = useApp();
  const intl = useIntl();

  return (
    <ShortcutCard
      Icon={Plus}
      label={intl.formatMessage(translations.addShortcut)}
      onClick={openAddShortcutDialog}
      variant="outlined"
    />
  );
};
