import { CardActionArea, styled } from '@afosto/components';

export const ActionArea = styled(CardActionArea)`
  color: ${(props) => props.theme.palette.primary[700]};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 112px;
  gap: ${(props) => props.theme.spacing(3)};
  align-items: flex-start;
  padding: ${(props) => props.theme.spacing(2.5)};
  transition: ${(props) =>
    props.theme.transitions.create('background-color', {
      duration: props.theme.transitions.duration.shortest,
    })};

  &:hover:not(.Mui-disabled) {
    background-color: ${(props) => props.theme.palette.primary[200]};
  }

  &.variant-image:not(.Mui-disabled) {
    background-color: rgba(6, 47, 75, 0.4);

    &:hover {
      background-color: rgba(6, 47, 75, 0.3);
    }
  }

  &.variant-filled:not(.Mui-disabled) {
    background-color: ${(props) => props.theme.palette.primary[200]};

    &:hover {
      background-color: ${(props) => props.theme.palette.common.white};
    }
  }

  &.color-success:not(.Mui-disabled) {
    color: ${(props) => props.theme.palette.success[700]};

    &.variant-filled {
      background-color: ${(props) => props.theme.palette.success[200]};
    }
  }

  &.Mui-disabled {
    background-color: ${(props) => props.theme.palette.gray[75]};
    color: ${(props) => props.theme.palette.gray[500]};
  }
`;
