import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  add: {
    id: 'common.add',
    defaultMessage: 'Add',
    description: 'Common label for add',
  },
  addingShortcut: {
    id: 'feedback.addingShortcut.message',
    defaultMessage: 'Adding shortcut',
    description: 'Common feedback message for adding shortcut',
  },
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Common label for close',
  },
  title: {
    id: 'actions.addShortcut',
    defaultMessage: 'Add shortcut',
    description: 'Common label for add shortcut action',
  },
});
