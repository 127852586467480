import { useIntl } from 'react-intl';
import { useFormContext } from '@afosto/components';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import { CashRegister, Receipt } from '../../../../icons/solid';
import { translations } from './translations';

export const PosActionsFormSection = () => {
  const intl = useIntl();

  // TODO: Fix when formProps are typed
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { formProps, setValue } = useFormContext();
  const { submitForm } = formProps || {};

  const handleSelectAction = (action: string) => () => {
    setValue('action', action);
    submitForm().catch(() => {
      // Do nothing.
    });
  };

  return (
    <Fieldset>
      <ActionsList>
        <CardSelector
          Icon={Receipt}
          label={intl.formatMessage(translations.printLastReceipt)}
          onSelect={handleSelectAction('PRINT_LAST_RECEIPT')}
        />
        <CardSelector
          Icon={CashRegister}
          label={intl.formatMessage(translations.openCashDrawer)}
          onSelect={handleSelectAction('OPEN_CASH_DRAWER')}
        />
      </ActionsList>
    </Fieldset>
  );
};
