import { Box } from '@afosto/components';
import { createFileRoute } from '@tanstack/react-router';
import { useApp } from '../../../components/AppProvider/hooks/useApp';
import { useCategory } from '../../../components/CategoryProvider/hooks/useCategory';
import { useChannel } from '../../../components/ChannelProvider/hooks/useChannel';
import { useOrder } from '../../../components/OrderProvider/hooks/useOrder';
import { beepError } from '../../../utils';
import { useScanner } from '../../../hooks/useScanner';
import { SearchToolbar } from '../../../components/SearchToolbar';
import { Shortcuts } from '../../../components/Shortcuts';
import { Search } from '../../../components/Search';
import { Receipt } from '../../../components/Receipt';
import { AddShortcutFormDialog } from '../../../components/AddShortcutFormDialog';
import { CancelOrderItemsFormDialog } from '../../../components/CancelOrderItemsFormDialog';
import { ConfirmDeleteOrderDialog } from '../../../components/ConfirmDeleteOrderDialog';
import { CheckoutDialog } from '../../../components/CheckoutDialog';
import { ManageContactDialog } from '../../../components/ManageContactDialog';
import { ManageOrganisationDialog } from '../../../components/ManageOrganisationDialog';
import { ManageOrderDiscountFormDialog } from '../../../components/ManageOrderDiscountFormDialog';
import { ManageOrderItemFormDialog } from '../../../components/ManageOrderItemFormDialog';
import { ManageOrderItemAdjustmentFormDialog } from '../../../components/ManageOrderItemAdjustmentFormDialog';
import { ManageShortcutsDialog } from '../../../components/ManageShortcutsDialog';
import { ManuallyAddProductFormDialog } from '../../../components/ManuallyAddProductFormDialog';
import { OrderActionsDialog } from '../../../components/OrderActionsDialog';
import { OrderNoteFormDialog } from '../../../components/OrderNoteFormDialog';
import { SelectCustomerFormDialog } from '../../../components/SelectCustomerFormDialog';
import * as Styled from './index.styles';

export const Route = createFileRoute('/_protected/_index/')({
  component: IndexComponent,
});

function IndexComponent() {
  const {
    closeAddShortcutDialog,
    closeManageShortcutsDialog,
    showAppActions,
    showAddShortcutDialog,
    showManageShortcutsDialog,
    showSearch,
  } = useApp();
  const { showSelectCategoryDialog } = useCategory();
  const { channel, showSelectChannelDialog } = useChannel();
  const {
    addItemToOrderByScan,
    closeAddCustomerDialog,
    closeCancelOrderItemsDialog,
    closeCheckoutDialog,
    closeConfirmDeleteOrderDialog,
    closeManageContactDialog,
    closeManageOrganisationDialog,
    closeManageOrderDiscountDialog,
    closeManageOrderItemDialog,
    closeManageOrderItemAdjustmentDialog,
    closeManuallyAddProductDialog,
    closeOrderActionsDialog,
    closeOrderNoteDialog,
    exitedManageContactDialog,
    exitedManageOrganisationDialog,
    exitedManageOrderItemDialog,
    exitedManageOrderItemAdjustmentDialog,
    loadOrderByNumber,
    selectedContact,
    selectedOrganisation,
    selectedItemAdjustment,
    selectedOrderItem,
    showAddCustomerDialog,
    showCancelOrderItemsDialog,
    showCheckoutDialog,
    showConfirmDeleteOrderDialog,
    showManageContactDialog,
    showManageOrganisationDialog,
    showManageOrderDiscountDialog,
    showManageOrderItemDialog,
    showManageOrderItemAdjustmentDialog,
    showManuallyAddProductDialog,
    showOrderActionsDialog,
    showOrderNoteDialog,
  } = useOrder();
  const enableScanner =
    !showAddShortcutDialog &&
    !showAppActions &&
    !showAddCustomerDialog &&
    !showCancelOrderItemsDialog &&
    !showCheckoutDialog &&
    !showConfirmDeleteOrderDialog &&
    !showManuallyAddProductDialog &&
    !showManageContactDialog &&
    !showManageOrganisationDialog &&
    !showManageOrderDiscountDialog &&
    !showManageOrderItemDialog &&
    !showManageOrderItemAdjustmentDialog &&
    !showManageShortcutsDialog &&
    !showOrderActionsDialog &&
    !showOrderNoteDialog &&
    !showSelectCategoryDialog &&
    !showSelectChannelDialog;

  const handleScanInput = async (input: string) => {
    if (channel) {
      const orderNumberRegex = new RegExp(
        `^${channel.abacus.order.prefix}-\\d+`
      );

      if (orderNumberRegex.test(input)) {
        const orderResult = await loadOrderByNumber(input).catch(() => null);

        if (!orderResult) {
          beepError();
          return;
        }

        return;
      }
    }

    addItemToOrderByScan(input, { beepEnabled: true }).catch(() => {
      // Do nothing
    });
  };

  useScanner({
    enabled: enableScanner,
    onScan: handleScanInput,
  });

  return (
    <>
      <Styled.Container>
        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            overflow: 'hidden',
          }}
        >
          {!showSearch && <SearchToolbar />}
          {!showSearch && <Shortcuts />}
          {showSearch && <Search />}
        </Box>
        <Styled.RightLayout>
          <Receipt />
        </Styled.RightLayout>
      </Styled.Container>
      <AddShortcutFormDialog
        onCancel={closeAddShortcutDialog}
        open={showAddShortcutDialog}
      />
      <CancelOrderItemsFormDialog
        onCancel={closeCancelOrderItemsDialog}
        open={showCancelOrderItemsDialog}
      />
      <ConfirmDeleteOrderDialog
        onClose={closeConfirmDeleteOrderDialog}
        open={showConfirmDeleteOrderDialog}
      />
      <CheckoutDialog onClose={closeCheckoutDialog} open={showCheckoutDialog} />
      <ManageContactDialog
        contact={selectedContact}
        onClose={closeManageContactDialog}
        onExited={exitedManageContactDialog}
        open={showManageContactDialog}
      />
      <ManageOrganisationDialog
        organisation={selectedOrganisation}
        onClose={closeManageOrganisationDialog}
        onExited={exitedManageOrganisationDialog}
        open={showManageOrganisationDialog}
      />
      <ManageOrderDiscountFormDialog
        onCancel={closeManageOrderDiscountDialog}
        open={showManageOrderDiscountDialog}
      />
      <ManageOrderItemFormDialog
        item={selectedOrderItem}
        onCancel={closeManageOrderItemDialog}
        onExited={exitedManageOrderItemDialog}
        open={showManageOrderItemDialog}
      />
      <ManageOrderItemAdjustmentFormDialog
        adjustment={selectedItemAdjustment}
        item={selectedOrderItem}
        onCancel={closeManageOrderItemAdjustmentDialog}
        onExited={exitedManageOrderItemAdjustmentDialog}
        open={showManageOrderItemAdjustmentDialog}
      />
      <ManageShortcutsDialog
        onClose={closeManageShortcutsDialog}
        open={showManageShortcutsDialog}
      />
      <ManuallyAddProductFormDialog
        onCancel={closeManuallyAddProductDialog}
        open={showManuallyAddProductDialog}
      />
      <OrderActionsDialog
        onClose={closeOrderActionsDialog}
        open={showOrderActionsDialog}
      />
      <OrderNoteFormDialog
        onCancel={closeOrderNoteDialog}
        open={showOrderNoteDialog}
      />
      <SelectCustomerFormDialog
        onCancel={closeAddCustomerDialog}
        open={showAddCustomerDialog}
      />
    </>
  );
}
