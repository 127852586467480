import { type ChangeEvent, type KeyboardEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useFormContext,
} from '@afosto/components';
import { useAsyncDebounce } from '@afosto/hooks';
import { useQuery } from '@tanstack/react-query';
import { Loader } from '../../../Loader';
import {
  type SearchResultsListItem,
  SearchResultsList,
} from '../../../SearchResultsList';
import { Times } from '../../../../icons/light';
import { searchContact, searchOrganisation } from '../../../../queries';
import { getFullName } from '../../../../utils';
import { translations } from './translations';
import type { SearchCustomerFormSectionProps } from './types';
import type { Contact } from '../../../../types';

export const SearchCustomerFormSection = (
  props: SearchCustomerFormSectionProps
) => {
  const { onSelectCustomer } = props;

  const intl = useIntl();
  const { watch } = useFormContext();
  const customerType = watch('customerType');

  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  const startSearch = useAsyncDebounce((value: string) => {
    setSearchQuery(value);
  }, 250);

  const placeholderTranslationKey: keyof typeof translations =
    customerType === 'organisation'
      ? 'searchAnExistingOrganisation'
      : 'searchAnExistingContact';

  const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value || '';

    setSearchInput(searchValue);
    startSearch(searchValue).catch(() => {
      // Do nothing
    });
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  };

  const handleClearSearchInput = () => {
    setSearchInput('');
    setSearchQuery('');
  };

  const { data: contactsResponse, isFetching: isFetchingContacts } = useQuery({
    ...searchContact(searchQuery),
    enabled: customerType !== 'organisation' && searchQuery?.length > 0,
  });
  const contactResults: SearchResultsListItem[] = (contactsResponse || []).map(
    (item) => ({
      ...item,
      description: (item.email || '') as string,
      label: getFullName({
        givenName: item.givenName,
        additionalName: item.additionalName,
        familyName: item.familyName,
      } as Contact),
      value: item.id,
    })
  );

  const { data: organisationsResponse, isFetching: isFetchingOrganisations } =
    useQuery({
      ...searchOrganisation(searchQuery),
      enabled: customerType === 'organisation' && searchQuery?.length > 0,
    });
  const organisationResults: SearchResultsListItem[] = (
    organisationsResponse || []
  ).map((item) => ({
    ...item,
    description: (item.email || '') as string,
    label: (item.name || '') as string,
    value: item.id,
  }));

  const isSearching = isFetchingContacts || isFetchingOrganisations;

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: searchInput ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClearSearchInput}
                variant="minimalLight2"
                size="large"
              >
                <Times />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        onChange={handleChangeSearchInput}
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage(
          translations[placeholderTranslationKey]
        )}
        sx={{ mb: 4 }}
        value={searchInput}
        fullWidth
      />
      {isSearching && <Loader sx={{ mt: 4 }} />}
      {!isSearching && (
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            mb: 6,
          }}
        >
          {searchQuery?.length > 0 && (
            <SearchResultsList
              items={
                customerType === 'organisation'
                  ? organisationResults
                  : contactResults
              }
              onSelectSearchResult={onSelectCustomer}
              showSelector
            />
          )}
          {!searchQuery?.length && (
            <Typography variant="bodyLarge" mt={4} sx={{ alignSelf: 'center' }}>
              <FormattedMessage {...translations.startASearch} />
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
