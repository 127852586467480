import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from '@afosto/components';
import { FullscreenDialog } from '../FullscreenDialog';
import { Loader } from '../Loader';
import { PrintersList } from '../PrintersList';
import { usePrint } from '../PrintProvider/hooks/usePrint';
import { NoPrintersFeedbackMessage } from './components/NoPrintersFeedbackMessage';
import { translations } from './translations';
import type { SelectPrinterDialogProps } from './types';

export const SelectPrinterDialog = (props: SelectPrinterDialogProps) => {
  const {
    appear = true,
    onClose,
    onExited,
    onSelectPrinter,
    ...otherProps
  } = props;

  const intl = useIntl();
  const {
    closeSelectPrinterDialog,
    isFetchingPrinterOptions,
    printerOptions,
    refetchPrinterOptions,
    selectPrinterDialogIntent,
  } = usePrint();
  const hasPrinters = printerOptions.length > 0;

  const handleClose = () => {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  };

  const handleSearchPrinters = () => {
    refetchPrinterOptions().catch(() => {
      // Do nothing.
    });
  };

  const handleSkip = () => {
    closeSelectPrinterDialog();
  };

  return (
    <FullscreenDialog
      {...otherProps}
      description={intl.formatMessage(translations.description)}
      hideDialogHeader={isFetchingPrinterOptions || !hasPrinters}
      onClose={onClose ? handleClose : undefined}
      title={intl.formatMessage(translations.title)}
      topBarProps={{
        actions: onClose && (
          <Link
            component="button"
            onClick={handleClose}
            fontWeight={500}
            type="button"
            variant="bodyLarge"
          >
            <FormattedMessage {...translations.close} />
          </Link>
        ),
      }}
      TransitionProps={{ appear, onExited }}
    >
      {isFetchingPrinterOptions && (
        <Loader
          message={`${intl.formatMessage(translations.searchingPrinters)}...`}
          sx={{
            mt: 6,
          }}
        />
      )}
      {!isFetchingPrinterOptions && hasPrinters && (
        <PrintersList
          printers={printerOptions}
          onSelectPrinter={onSelectPrinter}
        />
      )}
      {!isFetchingPrinterOptions && !hasPrinters && (
        <NoPrintersFeedbackMessage
          onSearchPrinters={handleSearchPrinters}
          onSkip={
            selectPrinterDialogIntent === 'installation'
              ? handleSkip
              : undefined
          }
        />
      )}
    </FullscreenDialog>
  );
};
