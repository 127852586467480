import { updateProfile } from '@afosto/profile-service/api';
import type { Shortcut } from '../queries';

export interface UpdateShortcutsInput {
  shortcuts: Shortcut[];
}

export const updateShortcuts = async ({ shortcuts }: UpdateShortcutsInput) => {
  await updateProfile({
    path: { path: `afosto.tenant.odr.pos.v1.shortcuts` },
    body: {
      data: {
        items: shortcuts.map((shortcut, idx) => ({
          ...shortcut,
          position: idx,
        })),
      },
    },
  });

  return updateShortcuts;
};
