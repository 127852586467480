import { type CSSProperties, type MouseEvent } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton } from '@afosto/components';
import { Trash } from '../../icons/solid';
import type { ShortcutSortableItemProps } from './types';

export const ShortcutSortableItem = (props: ShortcutSortableItemProps) => {
  const { children, id, onRemove } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  };

  const handleRemove = (event: MouseEvent<HTMLButtonElement>) => {
    if (onRemove && typeof onRemove === 'function') {
      onRemove(id);
    }

    event.stopPropagation();
    event.preventDefault();
    return false;
  };

  return (
    <div ref={setNodeRef} style={style}>
      <IconButton
        onClick={handleRemove}
        variant="outlined"
        size="medium"
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          cursor: 'pointer',
          pointerEvents: 'auto',
          zIndex: 1,
        }}
      >
        <Trash sx={{ color: (theme) => theme.palette.primary[700] }} />
      </IconButton>
      <div {...attributes} {...listeners} style={{ flex: 1, cursor: 'grab' }}>
        {children}
      </div>
    </div>
  );
};
