import { useIntl } from 'react-intl';
import { useFormContext } from '@afosto/components';
import { ActionsList } from '../../../ActionsList';
import { CardSelector } from '../../../CardSelector';
import { Fieldset } from '../../../Fieldset';
import {
  BadgePercent,
  CommentAltDots,
  Gem,
  HourGlassEmpty,
  Tag,
  Times,
  User,
} from '../../../../icons/solid';
import { translations } from './translations';
import { useAuthentication } from '@afosto/auth-react';

export const CartActionsFormSection = () => {
  const intl = useIntl();
  const { tenantId } = useAuthentication();

  // TODO: Fix when formProps are typed
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { formProps, setValue } = useFormContext();
  const { submitForm } = formProps || {};

  const handleSelectAction = (action: string) => () => {
    setValue('action', action);
    submitForm().catch(() => {
      // Do nothing.
    });
  };

  return (
    <Fieldset>
      <ActionsList>
        <CardSelector
          Icon={User}
          label={intl.formatMessage(translations.addCustomer)}
          onSelect={handleSelectAction('ADD_CUSTOMER')}
        />
        <CardSelector
          Icon={HourGlassEmpty}
          label={intl.formatMessage(translations.parkReceipt)}
          onSelect={handleSelectAction('PARK_RECEIPT')}
        />
        <CardSelector
          Icon={CommentAltDots}
          label={intl.formatMessage(translations.addNote)}
          onSelect={handleSelectAction('ADD_NOTE')}
        />
        <CardSelector
          Icon={BadgePercent}
          label={intl.formatMessage(translations.discounts)}
          onSelect={handleSelectAction('ORDER_DISCOUNT')}
        />
        <CardSelector
          Icon={Times}
          label={intl.formatMessage(translations.cancelProducts)}
          onSelect={handleSelectAction('CANCEL_PRODUCTS')}
        />
        <CardSelector
          Icon={Tag}
          label={intl.formatMessage(translations.manuallyAddProduct)}
          onSelect={handleSelectAction('MANUALLY_ADD_PRODUCT')}
        />
        {tenantId &&
          ['5719193282412544', '4607039130042368'].includes(tenantId) && (
            <CardSelector
              Icon={Gem}
              label={intl.formatMessage(translations.selectACategory)}
              onSelect={handleSelectAction('SELECT_CATEGORY')}
            />
          )}
      </ActionsList>
    </Fieldset>
  );
};
