import type { JSXElementConstructor } from 'react';
import type { SvgIconProps } from '@afosto/components';
import { Shop, ShoppingCart, Tag } from '../../icons/solid';

export const SHORTCUT_CATEGORIES = ['cart', 'pos', 'product'];

export const SHORTCUT_CATEGORY_ICON_MAPPING: {
  [key: string]: JSXElementConstructor<SvgIconProps>;
} = {
  cart: ShoppingCart,
  pos: Shop,
  product: Tag,
};
