import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  addCustomer: {
    id: 'actions.addCustomer',
    defaultMessage: 'Add customer',
    description: 'Common label for add customer action',
  },
  addNote: {
    id: 'common.addNote',
    defaultMessage: 'Add note',
    description: 'Common label for add note',
  },
  cancelProducts: {
    id: 'actions.cancelProducts',
    defaultMessage: 'Cancel products',
    description: 'Common label for cancel products action',
  },
  discounts: {
    id: 'common.discounts',
    defaultMessage: 'Discounts',
    description: 'Common label for discounts',
  },
  manuallyAddProduct: {
    id: 'actions.manuallyAddProduct',
    defaultMessage: 'Manually add product',
    description: 'Common label for manually add product action',
  },
  parkReceipt: {
    id: 'common.parkReceipt',
    defaultMessage: 'Park receipt',
    description: 'Common label for park receipt',
  },
  selectACategory: {
    id: 'actions.selectACategory',
    defaultMessage: 'Select a category',
    description: 'Common label for select a category action',
  },
});
