import type { ComponentType } from 'react';
import { CancelProductsShortcut } from '../components/CancelProductsShortcut';
import { CategoryShortcut } from '../components/CategoryShortcut';
import { CustomerShortcut } from '../components/CustomerShortcut';
import { ManuallyAddProductShortcut } from '../components/ManuallyAddProductShortcut';
import { NoteShortcut } from '../components/NoteShortcut';
import { OpenCashDrawerShortcut } from '../components/OpenCashDrawerShortcut';
import { OrderDiscountShortcut } from '../components/OrderDiscountShortcut';
import { ParkReceiptShortcut } from '../components/ParkReceiptShortcut';
import { PrintLastReceiptShortcut } from '../components/PrintLastReceiptShortcut';
import {
  ProductShortcut,
  type ProductShortcutProps,
} from '../components/ProductShortcut';
import type { ShortcutActionKey } from '../queries';

export type ShortcutPropsMapping = {
  addCustomer: Record<string, unknown>;
  addNote: Record<string, unknown>;
  cancelProducts: Record<string, unknown>;
  category: Record<string, unknown>;
  manuallyAddProduct: Record<string, unknown>;
  openCashDrawer: Record<string, unknown>;
  orderDiscount: Record<string, unknown>;
  parkReceipt: Record<string, unknown>;
  printLastReceipt: Record<string, unknown>;
  product: ProductShortcutProps;
  selectCategory: Record<string, unknown>;
};

export const SHORTCUT_ACTION_COMPONENTS_MAPPING: {
  [K in ShortcutActionKey]: ComponentType<ShortcutPropsMapping[K]>;
} = {
  addCustomer: CustomerShortcut,
  addNote: NoteShortcut,
  cancelProducts: CancelProductsShortcut,
  category: CategoryShortcut,
  manuallyAddProduct: ManuallyAddProductShortcut,
  openCashDrawer: OpenCashDrawerShortcut,
  orderDiscount: OrderDiscountShortcut,
  parkReceipt: ParkReceiptShortcut,
  printLastReceipt: PrintLastReceiptShortcut,
  product: ProductShortcut,
  selectCategory: CategoryShortcut,
};
