import { type ChangeEvent, type KeyboardEvent, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useFormContext,
} from '@afosto/components';
import { useAsyncDebounce } from '@afosto/hooks';
import { Loader } from '../../../Loader';
import { SearchResultsList } from '../../../SearchResultsList';
import { Times } from '../../../../icons/light';
import { searchProductSuggest } from '../../../../queries';
import { translations } from './translations';
import type { ProductSearchResultItem } from '../../../Search/types';

export const SearchProductFormSection = () => {
  const intl = useIntl();
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  // TODO: Fix when formProps are typed
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { formProps, getValues, reset } = useFormContext();
  const { submitForm } = formProps || {};

  // TODO: Fix when useAsyncDebounce is typed correctly
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const startSearch = useAsyncDebounce((value: string) => {
    setSearchQuery(value);
  }, 250);

  const handleChangeSearchInput = (event: ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value || '';

    setSearchInput(searchValue);
    startSearch(searchValue).catch(() => {
      // Do nothing
    });
  };

  const handleClearSearchInput = () => {
    setSearchInput('');
    setSearchQuery('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  };

  const handleSelectProduct = (result: ProductSearchResultItem) => () => {
    reset({
      ...getValues(),
      action: 'PRODUCT',
      product: result,
    });
    submitForm().catch(() => {
      // Do nothing.
    });
  };

  const { data: productsResponse, isFetching: isSearching } = useQuery({
    ...searchProductSuggest(searchInput),
    enabled: searchQuery?.length > 0,
  });
  const results = (productsResponse || []).map((product) => {
    const [image] = product.image || [];

    return {
      ...product,
      avatar: image,
      description: product.sku || '',
      label: product.label || product.sku || '',
      value: product.id,
    };
  });

  return (
    <>
      <TextField
        InputProps={{
          endAdornment: searchInput ? (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClearSearchInput}
                variant="minimalLight2"
                size="large"
              >
                <Times />
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
        onChange={handleChangeSearchInput}
        onKeyDown={handleKeyDown}
        placeholder={intl.formatMessage(translations.searchAnExistingProduct)}
        sx={{ mb: 4 }}
        value={searchInput}
        fullWidth
      />
      {isSearching && <Loader sx={{ mt: 4 }} />}
      {!isSearching && (
        <Box
          sx={{
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            display: 'flex',
            flexDirection: 'column',
            mb: 6,
          }}
        >
          {searchQuery?.length > 0 && (
            <SearchResultsList
              items={results}
              onSelectSearchResult={handleSelectProduct}
              showSelector
            />
          )}
          {!searchQuery?.length && (
            <Typography variant="bodyLarge" mt={4} sx={{ alignSelf: 'center' }}>
              <FormattedMessage {...translations.startASearch} />
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};
