import type { ComponentType } from 'react';
import { useQuery } from '@tanstack/react-query';
import { ScrollContainer } from '@afosto/components';
import { arrayFromNumber, camelize, isDefined } from '@afosto/utils';
import { closestCenter, DndContext, type DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import { ShortcutSortableItem } from '../ShortcutSortableItem';
import {
  SHORTCUT_ACTION_COMPONENTS_MAPPING,
  type ShortcutPropsMapping,
} from '../../constants/shortcutMappings';
import { getProductsBySku, type ShortcutActionKey } from '../../queries';
import * as Styled from './ShortcutsGrid.styles';
import type { LookupProduct } from '../../types';
import type { ShortcutsGridProps } from './types';
import { ShortcutCardSkeleton } from '../ShortcutCardSkeleton';

export const ShortcutsGrid = (props: ShortcutsGridProps) => {
  const {
    children,
    isLoading = false,
    isSortable = false,
    onSortingChanged,
    onRemove,
    shortcuts = [],
  } = props;

  const skus = [
    ...new Set(
      shortcuts
        .filter((shortcut) => shortcut.action?.toLowerCase() === 'product')
        .map((shortcut) => shortcut.settings?.sku ?? '')
        .filter((sku) => isDefined(sku))
    ),
  ].sort();

  const { data: products } = useQuery({
    ...getProductsBySku(skus),
    enabled: skus.length > 0,
  });
  const productsMappedBySku = (products || []).reduce(
    (acc, lookupProduct) => ({
      ...acc,
      [lookupProduct.sku]: lookupProduct,
    }),
    {} as { [key: string]: LookupProduct }
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (
      active.id !== over?.id &&
      onSortingChanged &&
      typeof onSortingChanged === 'function'
    ) {
      const oldIndex = shortcuts.findIndex((item) => item.id === active.id);
      const newIndex = shortcuts.findIndex((item) => item.id === over?.id);

      onSortingChanged(arrayMove(shortcuts, oldIndex, newIndex));
    }
  };

  const shortcutItems = shortcuts.map((shortcut) => {
    const { action, id, settings } = shortcut || {};
    const actionKey = camelize(action.toLowerCase()) as ShortcutActionKey;
    const ShortcutComponent = SHORTCUT_ACTION_COMPONENTS_MAPPING[
      actionKey
    ] as ComponentType<ShortcutPropsMapping[typeof actionKey]>;

    if (!ShortcutComponent) {
      return null;
    }

    const shortcutComponentProps = {
      ...(settings ?? {}),
    } as ShortcutPropsMapping[typeof actionKey];

    if (actionKey === 'product' && settings?.sku) {
      shortcutComponentProps.product = productsMappedBySku[settings.sku];
    }

    if (isSortable) {
      return (
        <ShortcutSortableItem key={id} id={id} onRemove={onRemove}>
          <ShortcutComponent {...shortcutComponentProps} preview />
        </ShortcutSortableItem>
      );
    }

    return <ShortcutComponent {...shortcutComponentProps} key={id} />;
  });

  if (isSortable) {
    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={shortcuts} strategy={rectSortingStrategy}>
          <Styled.Container>
            {shortcutItems}
            {children}
          </Styled.Container>
        </SortableContext>
      </DndContext>
    );
  }

  return (
    <ScrollContainer>
      <Styled.Container>
        {isLoading &&
          arrayFromNumber(8).map((idx) => <ShortcutCardSkeleton key={idx} />)}
        {!isLoading && (
          <>
            {shortcutItems}
            {children}
          </>
        )}
      </Styled.Container>
    </ScrollContainer>
  );
};
