import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Button, FeedbackMessage, Typography } from '@afosto/components';
import { translations } from './translations';
import type { NoPrintersFeedbackMessageProps } from './types';

export const NoPrintersFeedbackMessage = (
  props: NoPrintersFeedbackMessageProps
) => {
  const { onSearchPrinters, onSkip } = props;

  const intl = useIntl();

  return (
    <Box sx={{ mt: 6 }}>
      <FeedbackMessage
        description={intl.formatMessage(translations.description)}
        descriptionTypographyProps={{
          sx: {
            mb: 2,
          },
          variant: 'bodyLarge',
        }}
        severity="warning"
        title={intl.formatMessage(translations.title)}
        titleTypographyProps={{
          variant: 'h3',
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            justifyContent: 'center',
          }}
        >
          <Typography color="gray.800" component="span" variant="bodyLarge">
            {intl.formatMessage(translations.readDocumentationMessage)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              justifyContent: 'center',
            }}
          >
            <Button
              color="secondary"
              onClick={onSearchPrinters}
              size="extraLarge"
              sx={{
                width: '240px',
              }}
              variant="outlined"
              fullWidth
            >
              <FormattedMessage {...translations.searchPrinters} />
            </Button>
            {onSkip && (
              <Button
                onClick={onSkip}
                size="extraLarge"
                sx={{
                  width: '240px',
                }}
                fullWidth
              >
                <FormattedMessage {...translations.skip} />
              </Button>
            )}
          </Box>
        </Box>
      </FeedbackMessage>
    </Box>
  );
};
