import { useFormContext } from '@afosto/components';
import type { CancelableItemsCounterProps } from './types';
import type { CancelOrderItemsFormDialogFormData } from '../../types';

export const CancelableItemsCounter = (props: CancelableItemsCounterProps) => {
  const { items = [] } = props;
  const totalQuantity = (items || []).reduce(
    (acc, item) => acc + item.quantity,
    0
  );

  const { watch } = useFormContext();
  const itemsValue: CancelOrderItemsFormDialogFormData['items'] = watch(
    'items',
    {}
  );
  const totalCanceled = Object.values(itemsValue || {}).reduce(
    (acc: number, item) => {
      if (!isNaN(Number(item.quantity))) {
        return acc + Number(item.quantity);
      }

      return acc;
    },
    0
  );

  return ` (${totalCanceled}/${totalQuantity})`;
};
