import { useCallback, useEffect, useState } from 'react';
import { useAuthentication } from '@afosto/auth-react';
import { useGetLatest } from '@afosto/hooks';
import { uuid } from '@afosto/utils';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useChannel } from '../../ChannelProvider/hooks/useChannel';
import { type PrintCommand, usePrintCommands } from './usePrintCommands';
import { enqueuePrintJob as enqueuePrintJobMutationRequest } from '../../../mutations';
import { getPrintAccount, listPrinters } from '../../../queries';
import type { Printer } from '../../../types';

export type SelectPrinterDialogIntent = 'settings' | 'installation';

export const usePrintProvider = () => {
  const { isAuthenticated, isAuthorizing, tenantId } = useAuthentication();
  const { channel } = useChannel();

  const [printer, setPrinter] = useState<Printer | null>(null);
  const [selectPrinterDialogIntent, setSelectPrinterDialogIntent] =
    useState<SelectPrinterDialogIntent>('settings');
  const [isInitializingPrintService, setIsInitializingPrintService] =
    useState(true);
  const [showSelectPrinterDialog, setShowSelectPrinterDialog] = useState(false);
  const getLatestIsInitializingPrintService = useGetLatest(
    isInitializingPrintService
  );

  const printerStorageKey = `${tenantId}_printerId`;
  const printerInstallationSkippedKey = `${tenantId}_printerInstallationSkipped`;

  const printCommands = usePrintCommands();

  const getIsInstallationSkipped = useCallback(() => {
    const installationSkipped =
      window.localStorage.getItem(printerInstallationSkippedKey) ?? false;

    try {
      if (installationSkipped) {
        return JSON.parse(installationSkipped);
      }

      return false;
    } catch {
      return false;
    }
  }, [printerInstallationSkippedKey]);

  const getStoredPrinterId = useCallback(() => {
    const selectedPrinterId =
      window.localStorage.getItem(printerStorageKey) ?? '';

    try {
      if (selectedPrinterId) {
        return JSON.parse(selectedPrinterId);
      }

      return null;
    } catch {
      return null;
    }
  }, [printerStorageKey]);

  const storeIsInstallationSkipped = useCallback(
    (installationSkipped: boolean) => {
      window.localStorage.setItem(
        printerInstallationSkippedKey,
        JSON.stringify(installationSkipped)
      );
    },
    [printerInstallationSkippedKey]
  );

  const storePrinterId = useCallback(
    (selectedPrinterId: number) => {
      window.localStorage.setItem(
        printerStorageKey,
        JSON.stringify(selectedPrinterId)
      );
    },
    [printerStorageKey]
  );

  const { data: printAccount, isFetched: printAccountIsFetched } = useQuery({
    ...getPrintAccount(),
    enabled: isAuthenticated,
    select: (response) => response?.data || [],
  });
  const printingEnabled = printAccount?.isActive === true;

  const {
    data: printerOptions,
    isFetching: isFetchingPrinterOptions,
    isFetched: printerOptionsIsFetched,
    refetch: refetchPrinterOptions,
  } = useQuery({
    ...listPrinters({
      pageSize: 250,
    }),
    enabled: printingEnabled,
    select: (response) => response?.data || [],
  });

  const { mutateAsync: enqueuePrintJobMutation } = useMutation({
    mutationFn: enqueuePrintJobMutationRequest,
  });

  const closeSelectPrinterDialog = useCallback(() => {
    if (selectPrinterDialogIntent === 'installation') {
      window.localStorage.setItem(
        printerInstallationSkippedKey,
        JSON.stringify(true)
      );
    }

    setShowSelectPrinterDialog(false);
  }, [printerInstallationSkippedKey, selectPrinterDialogIntent]);

  const exitedSelectPrinterDialog = useCallback(() => {
    if (selectPrinterDialogIntent !== 'settings') {
      setSelectPrinterDialogIntent('settings');
    }
  }, [selectPrinterDialogIntent]);

  const openSelectPrinterDialog = useCallback(
    (intent: SelectPrinterDialogIntent = 'settings') => {
      if (intent !== selectPrinterDialogIntent) {
        setSelectPrinterDialogIntent(intent);
      }

      setShowSelectPrinterDialog(true);
    },
    [selectPrinterDialogIntent]
  );

  const createSplContent = useCallback((commands: object[] = []) => {
    return {
      contentType: 'SPL',
      content: btoa(JSON.stringify(commands)),
    };
  }, []);

  const printJob = useCallback(
    async ({ commands }: { commands: PrintCommand[] }) => {
      if (!printer) {
        throw new Error('Printer not found');
      }

      return enqueuePrintJobMutation({
        await: 'PRINTED',
        jobs: [
          {
            id: uuid(),
            printerId: printer.id,
            ...createSplContent(commands),
          },
        ],
      });
    },
    [createSplContent, enqueuePrintJobMutation, printer]
  );

  const selectPrinter = useCallback(
    (printer: Printer) => {
      setPrinter(printer);
      storePrinterId(printer.id);
      closeSelectPrinterDialog();
    },
    [closeSelectPrinterDialog, storePrinterId]
  );

  useEffect(() => {
    const initializePrintService = async () => {
      const installationSkipped = getIsInstallationSkipped();

      try {
        const selectedPrinterId = getStoredPrinterId();
        const selectedPrinter = selectedPrinterId
          ? (printerOptions || []).find(
              ({ id }) => id === Number(selectedPrinterId)
            )
          : null;

        if (selectedPrinter) {
          setPrinter(selectedPrinter);
        }

        if (installationSkipped) {
          setIsInitializingPrintService(false);
          return;
        }

        if (!selectedPrinter && printerOptions?.length === 1) {
          const [firstPrinter] = printerOptions || [];
          setPrinter(firstPrinter);
          storePrinterId(firstPrinter.id);
        } else if (!selectedPrinter) {
          openSelectPrinterDialog('installation');
        }

        setIsInitializingPrintService(false);
      } catch {
        if (!installationSkipped) {
          openSelectPrinterDialog('installation');
        }

        setIsInitializingPrintService(false);
      }
    };

    const latestIsInitializingPrintService =
      getLatestIsInitializingPrintService();

    if (
      latestIsInitializingPrintService &&
      ((printAccountIsFetched && !printAccount) ||
        (printAccountIsFetched && printerOptionsIsFetched)) &&
      channel?.id
    ) {
      if (printAccount?.isActive) {
        initializePrintService().catch(() => {
          // Do nothing.
        });
      } else {
        setIsInitializingPrintService(false);
      }
    }
  }, [
    getIsInstallationSkipped,
    getLatestIsInitializingPrintService,
    getStoredPrinterId,
    channel?.id,
    openSelectPrinterDialog,
    printAccount,
    printAccountIsFetched,
    printerOptions,
    printerOptionsIsFetched,
    storeIsInstallationSkipped,
    storePrinterId,
  ]);

  useEffect(() => {
    if (isInitializingPrintService && !isAuthorizing && !isAuthenticated) {
      setIsInitializingPrintService(false);
    }
  }, [isAuthorizing, isAuthenticated, isInitializingPrintService]);

  return {
    closeSelectPrinterDialog,
    exitedSelectPrinterDialog,
    isInitializingPrintService,
    isFetchingPrinterOptions,
    openSelectPrinterDialog,
    printCommands,
    printingEnabled,
    printer,
    printJob,
    printerOptions: printerOptions || [],
    refetchPrinterOptions,
    selectPrinter,
    selectPrinterDialogIntent,
    showSelectPrinterDialog,
  };
};
