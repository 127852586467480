import { defineMessages } from 'react-intl';

export const translations = defineMessages({
  close: {
    id: 'common.close',
    defaultMessage: 'Close',
    description: 'Common label for close',
  },
  description: {
    id: 'components.selectPrinterDialog.description',
    defaultMessage: 'Select the printer you want to use',
    description: 'Description of the select printer dialog',
  },
  searchingPrinters: {
    id: 'feedback.searchingPrinters.message',
    defaultMessage: 'Searching printers',
    description: 'Message for the searching printers feedback',
  },
  title: {
    id: 'components.selectPrinterDialog.title',
    defaultMessage: 'Select printer',
    description: 'Title of the select printer dialog',
  },
});
