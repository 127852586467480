import { Card, Skeleton, Typography } from '@afosto/components';
import * as Styled from './ShortcutCardSkeleton.styles';

export const ShortcutCardSkeleton = () => {
  return (
    <Card>
      <Styled.ActionArea>
        <Skeleton variant="rounded" height={24} width={24} />
        <Typography
          component="div"
          variant="bodyLarge"
          sx={{ mt: 'auto', width: '100%' }}
        >
          <Skeleton variant="text" width="60%" />
        </Typography>
      </Styled.ActionArea>
    </Card>
  );
};
