import { uuid } from '@afosto/utils';
import type { Shortcut } from '../queries';

export const DEFAULT_SHORTCUTS: Shortcut[] = [
  {
    id: uuid(),
    action: 'ADD_CUSTOMER',
    position: 0,
    settings: {},
  },
  {
    id: uuid(),
    action: 'CANCEL_PRODUCTS',
    position: 1,
    settings: {},
  },
  {
    id: uuid(),
    action: 'MANUALLY_ADD_PRODUCT',
    position: 2,
    settings: {},
  },
  {
    id: uuid(),
    action: 'ORDER_DISCOUNT',
    position: 3,
    settings: {},
  },
  {
    id: uuid(),
    action: 'ADD_NOTE',
    position: 4,
    settings: {},
  },
];
