import { clsx } from 'clsx';
import { Box, Card, Typography } from '@afosto/components';
import * as Styled from './ShortcutCard.styles';
import type { ShortcutCardProps } from './types';

export const ShortcutCard = (props: ShortcutCardProps) => {
  const {
    backgroundImage,
    color = 'default',
    disabled = false,
    Icon,
    label,
    onClick,
    preview = false,
    variant = 'filled',
  } = props;
  const backgroundImageProps = {
    src: backgroundImage,
  };

  return (
    <Card
      sx={{
        ...(backgroundImage
          ? {
              zIndex: 0,
              position: 'relative',
            }
          : {}),
      }}
    >
      {backgroundImage && (
        <Box
          component="img"
          {...backgroundImageProps}
          sx={{
            position: 'absolute',
            inset: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            zIndex: -1,
          }}
        />
      )}
      <Styled.ActionArea
        className={clsx({
          [`color-${color}`]: color,
          [`variant-${variant}`]: variant,
        })}
        disabled={!preview && disabled}
        onClick={!preview ? onClick : undefined}
      >
        {Icon && <Icon sx={{ fontSize: 24 }} />}
        <Typography
          color={backgroundImage ? 'white' : 'inherit'}
          variant="bodyLarge"
          fontWeight={500}
          sx={{ mt: 'auto' }}
        >
          {label}
        </Typography>
      </Styled.ActionArea>
    </Card>
  );
};
